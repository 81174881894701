// const tradeProduct = document.querySelector('.product_cat-trade .amount');
// const node = document.createElement('span');
// const textnode = document.createTextNode('(including VAT)');

// node.appendChild(textnode);
// tradeProduct.appendChild(node);

var tradeDivs = document.querySelectorAll(".product_cat-trade .amount");

for (var i = 0; i < tradeDivs.length; i++) {
  tradeDivs[i].innerHTML = tradeDivs[i].innerHTML + "<span>(including VAT)<span>";
}